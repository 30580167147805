<template>
  <div>
    <b-tabs
      v-if="current !== null"
      class="workflow-steps"
      v-model="currentTabIndex"
      :aria-busy="isLoading"
      @changed="onTabsChanged"
      @input="currentTabChanged"
      @activate-tab="onTabActivated"
    >
      <b-tab
        v-for="(option, index) in options"
        :key="`${option.id}-${kebabcase(option.name)}`"
        :title-link-attributes="{ href: buildTabUrl(option) }"
        :title="`${option.name}${format(option.name)}`"
      >
        <workflow-reports-table
          v-if="currentOption !== null && option.id === currentOption.id"
          :ref="`report-table-${index}`"
          :reports="workflows"
          :step="option.name"
          :loading="isLoading"
          @workflow-report-table-scroll-completed="onReportTableScrollCompleted"
        />
      </b-tab>
    </b-tabs>

    <workflow-report-action-dialog
      :show="showActionsDialog"
      :action-recheck="isActionRecheckByReset"
      :action-flag="isActionFlag"
      :flag-colours="flagColours"
      :flag-comments="reportFlagComments"
      :flag-colour="reportFlagColour"
      :title="actionsDialogTitle"
      @ok="onActionOk"
      @cancel="onActionCancel"
      @close="onActionClose"
    />

    <workflow-report-action-dialog
      :show="showBulkActionsDialog"
      :action-recheck="isActionRecheckByReset"
      :action-flag="isActionFlag"
      :flag-colours="flagColours"
      :flag-comments="reportFlagComments"
      :flag-colour="reportFlagColour"
      :title="bulkActionsDialogTitle"
      @ok="onBulkActionOk"
      @cancel="onBulkActionCancel"
      @close="onBulkActionClose"
    />

    <workflow-report-action-dialog
      :show="showReleasePendingDialog"
      :hide-comments="true"
      :title="releasePendingDialogTitle"
      @ok="onReleasePendingOk"
      @cancel="onReleasePendingCancel"
      @close="onReleasePendingCancel"
    />

    <bulk-run-replacement-workflow-report-dialog
      :show="showRunReplacementDialog"
      :title="bulkActionsDialogTitle"
      @ok="onBulkActionOk"
      @cancel="onBulkActionCancel"
      @close="onBulkActionClose"
    />
  </div>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';

import { namespace } from 'vuex-class';
import { Workflow, WorkflowDate, WorkflowPage, WorkflowOption, WorkflowReport, WorkflowFlag } from '@/store/workflow/state';
import throttle from 'lodash.throttle';
import WorkflowReportsTable from '@/components/workflow/workflow-reports-table.vue';
import WorkflowReportActionDialog from './workflow-report-action-dialog.vue';
import BulkRunReplacementWorkflowReportDialog from './bulk-run-replacement-workflow-report-dialog.vue';
import { kebabcase, camelcase } from '@/utilities/text.utils';
import { Route } from 'vue-router';
import { BTab } from 'bootstrap-vue';
import { date } from '@/filters/date';
import { TenantResponse } from '../../api/responses/tenant/tenant-response';
import * as Sentry from '@sentry/browser';
import { FileUtils } from '@/utilities/file.utils';

const workflowModule = namespace('workflow');
const tenantModule = namespace('tenant');

@Component({
  components: {
    WorkflowReportsTable,
    WorkflowReportActionDialog,
    BulkRunReplacementWorkflowReportDialog,
  },
})
export default class WorkflowSteps extends Mixins(BvToastMixin) {
  @workflowModule.Getter('isLoading') isLoading!: boolean;
  @workflowModule.Getter current!: Workflow | null;
  @workflowModule.Getter workflows!: Array<WorkflowReport>;
  @workflowModule.Getter options!: Array<WorkflowOption>;
  @workflowModule.Getter currentOption!: WorkflowOption | null;
  @workflowModule.Getter flagColours!: Array<WorkflowFlag> | null;

  @workflowModule.Getter private selectedValuationDate!: WorkflowDate | null;
  @workflowModule.Getter selectedEntityIds!: string | null;
  @workflowModule.Getter private pages!: Array<WorkflowPage>;
  @tenantModule.Getter private selectedReportGroup!: { id: number, name: string } | null;
  @tenantModule.Getter('current') private currentTenant!: TenantResponse | null;

  currentTabIndex = -1;
  showActionsDialog = false;
  actionsDialogTitle: string | null = null;
  showBulkActionsDialog = false;
  bulkActionsDialogTitle: string | null = null;
  releasePendingDialogTitle: string | null = null;
  releasePendingItems: Array<WorkflowReport> | null = null;
  showReleasePendingDialog = false;
  showRunReplacementDialog = false;
  isActionRecheckByReset = false;
  isActionFlag = false;
  reportFlagComments: string | null = null;
  reportFlagColour = 1;

  private actionToDispatch: string | null = null;
  private additionActionDispatchOptions = {};
  private actionItem: WorkflowReport | null = null;
  private bulkActionToDispatch: string | null = null;
  private bulkAdditionActionDispatchOptions = {};
  private bulkActionItems: Array<WorkflowReport> | null = null;
  private isWorkflowMultiUpdate = true;

  get workflowDate(): WorkflowDate | null {
    if (this.current == null || this.selectedValuationDate == null) {
      return null;
    }

    const match = this.current.dates.find((date) => date.valuationDate === this.selectedValuationDate!.valuationDate) || null;

    return match;
  }

  kebabcase = kebabcase;

  created() {
    this.$root.$on('workflow-report-approve-clicked', this.onWorkflowReportApproveClicked);
    this.$root.$on('workflow-report-reject-clicked', this.onWorkflowReportRejectClicked);
    this.$root.$on('workflow-report-flag-clicked', this.onWorkflowReportFlagClicked);
    this.$root.$on('workflow-report-edit-flag-clicked', this.onWorkflowReportEditFlagClicked);
    this.$root.$on('workflow-report-unflag-clicked', this.onWorkflowReportUnflagClicked);
    this.$root.$on('workflow-report-send-delayed-commentary-clicked', this.onWorkflowReportSendDelayedCommentaryClicked);
    this.$root.$on('workflow-report-cancel-commentary-reaquisition-clicked', this.onWorkflowReportCancelCommentaryRaquisitionClicked);
    this.$root.$on('workflow-report-delete-clicked', this.onWorkflowReportDeleteClicked);
    this.$root.$on('workflow-report-reacquire-commentary-clicked', this.onWorkflowReportReaquireCommentaryClicked);
    this.$root.$on('workflow-report-rerender-clicked', this.onWorkflowReportRerenderClicked);
    this.$root.$on('workflow-report-recheck-data-clicked', this.onWorkflowReportRecheckClicked);
    this.$root.$on('workflow-report-release-draft-clicked', this.onWorkflowReportReleaseDraftClicked);
    this.$root.$on('workflow-report-resend-commentary-clicked', this.onWorkflowReportResendCommentaryClicked);
    this.$root.$on('workflow-report-resend-approval-clicked', this.onWorkflowReportResendApprovalClicked);
    this.$root.$on('workflow-report-resend-approval-for-deadline-clicked', this.onWorkflowReportResendApprovalForDeadlineClicked);
    this.$root.$on('workflow-report-return-for-approval-one-clicked', this.onWorkflowReportReturnForApprovalOneClicked);
    this.$root.$on('workflow-report-return-for-approval-two-clicked', this.onWorkflowReportReturnForApprovalTwoClicked);
    this.$root.$on('workflow-report-return-for-approval-three-clicked', this.onWorkflowReportReturnForApprovalThreeClicked);
    this.$root.$on('workflow-report-return-for-approval-four-clicked', this.onWorkflowReportReturnForApprovalFourClicked);
    this.$root.$on('workflow-report-ignore-data-issues-clicked', this.onWorkflowReportIgnoreNewDataIssuesClicked);

    this.$root.$on('bulk-workflow-report-approve-clicked', this.onWorkflowReportBulkApproveClicked);
    this.$root.$on('bulk-workflow-report-reject-clicked', this.onWorkflowReportBulkRejectClicked);
    this.$root.$on('bulk-workflow-report-download', this.onWorkflowReportBulkDownloadClicked);
    this.$root.$on('bulk-workflow-report-flag-clicked', this.onWorkflowReportBulkFlagClicked);
    this.$root.$on('bulk-workflow-report-unflag-clicked', this.onWorkflowReportBulkUnflagClicked);
    this.$root.$on('bulk-workflow-report-send-delayed-commentary-clicked', this.onWorkflowReportBulkSendDelayedCommentaryClicked);
    this.$root.$on('bulk-workflow-report-cancel-commentary-reaquisition-clicked', this.onWorkflowReportBulkCancelCommentaryRaquisitionClicked);
    this.$root.$on('bulk-workflow-report-delete-clicked', this.onWorkflowReportBulkDeleteClicked);
    this.$root.$on('bulk-workflow-report-reacquire-commentary-clicked', this.onWorkflowReportBulkReaquireCommentaryClicked);
    this.$root.$on('bulk-workflow-report-rerender-clicked', this.onWorkflowReportBulkRerenderClicked);
    this.$root.$on('bulk-workflow-report-recheck-data-clicked', this.onWorkflowReportBulkRecheckClicked);
    this.$root.$on('bulk-workflow-report-release-draft-clicked', this.onWorkflowReportBulkReleaseDraftClicked);
    this.$root.$on('bulk-workflow-report-resend-commentary-clicked', this.onWorkflowReportBulkResendCommentaryClicked);
    this.$root.$on('bulk-workflow-report-resend-approval-clicked', this.onWorkflowReportBulkResendApprovalClicked);
    this.$root.$on('bulk-workflow-report-resend-approval-for-deadline-clicked', this.onWorkflowReportBulkResendApprovalForDeadlineClicked);
    this.$root.$on('bulk-workflow-report-return-for-approval-one-clicked', this.onWorkflowReportBulkReturnForApprovalOneClicked);
    this.$root.$on('bulk-workflow-report-return-for-approval-two-clicked', this.onWorkflowReportBulkReturnForApprovalTwoClicked);
    this.$root.$on('bulk-workflow-report-return-for-approval-three-clicked', this.onWorkflowReportBulkReturnForApprovalThreeClicked);
    this.$root.$on('bulk-workflow-report-return-for-approval-four-clicked', this.onWorkflowReportBulkReturnForApprovalFourClicked);
    this.$root.$on('bulk-workflow-report-run-replacement-clicked', this.onWorkflowReportBulkRunReplacementClicked);
    this.$root.$on('bulk-workflow-report-ignore-new-data-warnings-clicked', this.onWorkflowReportBulkIgnoreNewDataWarningsClicked);

    this.$root.$on('workflow-report-release-pending-clicked', this.onWorkflowReportReleasePendingClicked);
  }

  beforeDestroy() {
    this.$root.$off('workflow-report-approve-clicked', this.onWorkflowReportApproveClicked);
    this.$root.$off('workflow-report-reject-clicked', this.onWorkflowReportRejectClicked);
    this.$root.$off('workflow-report-flag-clicked', this.onWorkflowReportFlagClicked);
    this.$root.$off('workflow-report-edit-flag-clicked', this.onWorkflowReportEditFlagClicked);
    this.$root.$off('workflow-report-unflag-clicked', this.onWorkflowReportUnflagClicked);
    this.$root.$off('workflow-report-send-delayed-commentary-clicked', this.onWorkflowReportSendDelayedCommentaryClicked);
    this.$root.$off('workflow-report-cancel-commentary-reaquisition-clicked', this.onWorkflowReportCancelCommentaryRaquisitionClicked);
    this.$root.$off('workflow-report-delete-clicked', this.onWorkflowReportDeleteClicked);
    this.$root.$off('workflow-report-reacquire-commentary-clicked', this.onWorkflowReportReaquireCommentaryClicked);
    this.$root.$off('workflow-report-rerender-clicked', this.onWorkflowReportRerenderClicked);
    this.$root.$off('workflow-report-recheck-data-clicked', this.onWorkflowReportRecheckClicked);
    this.$root.$off('workflow-report-release-draft-clicked', this.onWorkflowReportReleaseDraftClicked);
    this.$root.$off('workflow-report-resend-commentary-clicked', this.onWorkflowReportResendCommentaryClicked);
    this.$root.$off('workflow-report-resend-approval-clicked', this.onWorkflowReportResendApprovalClicked);
    this.$root.$off('workflow-report-resend-approval-for-deadline-clicked', this.onWorkflowReportResendApprovalForDeadlineClicked);
    this.$root.$off('workflow-report-return-for-approval-one-clicked', this.onWorkflowReportReturnForApprovalOneClicked);
    this.$root.$off('workflow-report-return-for-approval-two-clicked', this.onWorkflowReportReturnForApprovalTwoClicked);
    this.$root.$off('workflow-report-return-for-approval-three-clicked', this.onWorkflowReportReturnForApprovalThreeClicked);
    this.$root.$off('workflow-report-return-for-approval-four-clicked', this.onWorkflowReportReturnForApprovalFourClicked);
    this.$root.$off('workflow-report-ignore-data-issues-clicked', this.onWorkflowReportIgnoreNewDataIssuesClicked);

    this.$root.$off('bulk-workflow-report-approve-clicked', this.onWorkflowReportBulkApproveClicked);
    this.$root.$off('bulk-workflow-report-reject-clicked', this.onWorkflowReportBulkRejectClicked);
    this.$root.$off('bulk-workflow-report-download', this.onWorkflowReportBulkDownloadClicked);
    this.$root.$off('bulk-workflow-report-flag-clicked', this.onWorkflowReportBulkFlagClicked);
    this.$root.$off('bulk-workflow-report-unflag-clicked', this.onWorkflowReportBulkUnflagClicked);
    this.$root.$off('bulk-workflow-report-send-delayed-commentary-clicked', this.onWorkflowReportBulkSendDelayedCommentaryClicked);
    this.$root.$off('bulk-workflow-report-cancel-commentary-reaquisition-clicked', this.onWorkflowReportBulkCancelCommentaryRaquisitionClicked);
    this.$root.$off('bulk-workflow-report-delete-clicked', this.onWorkflowReportBulkDeleteClicked);
    this.$root.$off('bulk-workflow-report-reacquire-commentary-clicked', this.onWorkflowReportBulkReaquireCommentaryClicked);
    this.$root.$off('bulk-workflow-report-rerender-clicked', this.onWorkflowReportBulkRerenderClicked);
    this.$root.$off('bulk-workflow-report-recheck-data-clicked', this.onWorkflowReportBulkRecheckClicked);
    this.$root.$off('bulk-workflow-report-release-draft-clicked', this.onWorkflowReportBulkReleaseDraftClicked);
    this.$root.$off('bulk-workflow-report-resend-commentary-clicked', this.onWorkflowReportBulkResendCommentaryClicked);
    this.$root.$off('bulk-workflow-report-resend-approval-clicked', this.onWorkflowReportBulkResendApprovalClicked);
    this.$root.$off('bulk-workflow-report-resend-approval-for-deadline-clicked', this.onWorkflowReportBulkResendApprovalForDeadlineClicked);
    this.$root.$off('bulk-workflow-report-return-for-approval-one-clicked', this.onWorkflowReportBulkReturnForApprovalOneClicked);
    this.$root.$off('bulk-workflow-report-return-for-approval-two-clicked', this.onWorkflowReportBulkReturnForApprovalTwoClicked);
    this.$root.$off('bulk-workflow-report-return-for-approval-three-clicked', this.onWorkflowReportBulkReturnForApprovalThreeClicked);
    this.$root.$off('bulk-workflow-report-return-for-approval-four-clicked', this.onWorkflowReportBulkReturnForApprovalFourClicked);
    this.$root.$off('bulk-workflow-report-run-replacement-clicked', this.onWorkflowReportBulkRunReplacementClicked);
    this.$root.$off('bulk-workflow-report-ignore-new-data-warnings-clicked', this.onWorkflowReportBulkIgnoreNewDataWarningsClicked);

    this.$root.$off('workflow-report-release-pending-clicked', this.onWorkflowReportReleasePendingClicked);
  }

  async mounted(): Promise<void> {
    const { option } = this.$route.params;

    if (option === undefined) {
      return;
    }

    const index = this.options.findIndex((o) => kebabcase(o.name) === option);

    this.currentTabIndex = index;
  }

  format(displayName: string): string {
    const count = this.getStepCount(displayName);

    if (count !== undefined) {
      if (this.currentOption?.name === displayName) {
        return ` (${this.workflows.length}/${count})`;
      }

      return ` (${count})`;
    }

    return '';
  }

  buildTabUrl(option: WorkflowOption): string {
    return `/${kebabcase(this.currentTenant!.name)}` +
      `/workflow/${kebabcase(this.selectedReportGroup!.name)}` +
      `/${kebabcase(date(this.selectedValuationDate!.displayDate, this.current!.dateFormat))}` +
      `/${kebabcase(option.name)}`;
  }

  private getStepCount(stepName: string): number | undefined {
    const date = this.workflowDate;

    if (date === null || date === undefined) {
      return undefined;
    }

    const name = camelcase(stepName);

    const count = date.options[name] as number | undefined;

    return count;
  }

  async currentTabChanged(index: number): Promise<void> {
    const option = this.options[index];

    if (option === undefined) {
      return;
    }

    const next = this.options[index];

    this.$root.$emit('all-workflow-reports-deselected');
    this.onBulkActionCancel();
    this.onActionCancel();

    try {
      if (this.$route.params.option !== kebabcase(next.name)) {
        // NOTE(Dan): We could have the case that either a new tab has shown, or been hidden (e.g. Rejected).
        //            So, we need to check 2 cases:
        //
        //            1. A new tab has been added, so we need to update the currentTabIndex to be the new correct value.
        //            2. The current tab has been removed, so we need to figure out which tab to move to?

        const tabIndex = this.options.findIndex(o => kebabcase(o.name) === this.$route.params.option);

        if (tabIndex > -1) {
          // Case 1
          this.$nextTick(() => {
            this.currentTabIndex = tabIndex;
          });
        } else {
          // Case 2
          // await this.$router.push({ name: 'workflow', params: { ...this.$route.params, option: kebabcase(next.name) } });
        }
      }
    } catch (err) {
      // We catch the navigation cancelled here, we don't need to log to Sentry as the request has been replaced.
    }
  }

  async onTabActivated(newIndex: number): Promise<void> {
    const match = this.options[newIndex];

    if (match === undefined) {
      return;
    }

    if (this.$route.params.option === undefined || this.$route.params.option !== kebabcase(match.name)) {
      try {
        const { reportId, ...params } = this.$route.params;
        await this.$router.push({ name: 'workflow', params: { ...params, option: kebabcase(match.name) } });
      } catch (err) {

      }
    }
  }

  async onTabsChanged(newTabs: Array<BTab>, oldTabs: Array<BTab>): Promise<void> {
    if (this.$route.params.option !== undefined) {
      const index = this.options.findIndex((o) => kebabcase(o.name) === this.$route.params.option);

      if (index > -1) {
        this.currentTabIndex = index;
      }
    }
  }

  @Watch('$route')
  private async onRouteChanged(to: Route, from: Route): Promise<void> {
    const toOption = to.params.option;
    const fromOption = from?.params.option;

    if (toOption !== fromOption) {
      const index = this.options.findIndex((o) => kebabcase(o.name) === toOption);

      if (index < 0) {
        return;
      }
      this.currentTabIndex = index;
    }
  }

  @Watch('options')
  private async onOptionsChanged(): Promise<void> {
    if (this.currentOption?.id === this.options[this.currentTabIndex]?.id) {
      return;
    }

    await this.currentTabChanged(this.currentTabIndex);
  }

  onReportTableScrollCompleted = throttle(this.loadNextPageAsync, 1000);
  private async loadNextPageAsync(): Promise<void> {
    if (this.pages[this.pages.length - 1]!.pageSize > this.pages[this.pages.length - 1]!.workflows.length) {
      return;
    }

    const option = this.currentOption;

    if (option === undefined || option === null) {
      return;
    }

    const status = option.id;

    await this.$store.dispatch('workflow/getWorkflowPageAsync', { status: status, reportGroupId: this.current!.reportGroupId, valuationDate: this.selectedValuationDate!.valuationDate, page: this.pages[this.pages.length - 1]!.page + 1, pageSize: this.current!.itemsPerPage, entityIds: this.selectedEntityIds });
  }

  private async onWorkflowReportApproveClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Approve report #${item.id}`;
    this.actionToDispatch = 'workflow/approveWorkflowReportAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportRejectClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Reject report #${item.id}`;
    this.actionToDispatch = 'workflow/rejectWorkflowReportAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportFlagClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Flag report #${item.id}`;
    this.actionToDispatch = 'workflow/flagWorkflowReportAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.isActionFlag = true;
    this.reportFlagComments = item.flagged ? item.flagReason === null ? null : item.flagReason.substring(item.flagReason.indexOf(':') + 1).trim() : null;
    this.reportFlagColour = item.flagColour === 0 ? 1 : item.flagColour;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportEditFlagClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Edit Flag for report #${item.id}`;
    this.actionToDispatch = 'workflow/editFlagWorkflowReportAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.isActionFlag = true;
    this.reportFlagComments = item.flagged ? item.flagReason === null ? null : item.flagReason.substring(item.flagReason.indexOf(':') + 1).trim() : null;
    this.reportFlagColour = item.flagColour === 0 ? 1 : item.flagColour;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportUnflagClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Unflag report #${item.id}`;
    this.actionToDispatch = 'workflow/unflagWorkflowReportAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportSendDelayedCommentaryClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Send delayed commentary now for report #${item.id}`;
    this.actionToDispatch = 'workflow/sendWorkflowReportDelayedCommentaryAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportCancelCommentaryRaquisitionClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Cancel commentary reaquisition for #${item.id}`;
    this.actionToDispatch = 'workflow/cancelWorkflowReportCommentaryReaquisitionAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportDeleteClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Delete report #${item.id}`;
    this.actionToDispatch = 'workflow/deleteWorkflowReportAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportReaquireCommentaryClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Reaquire commentary for #${item.id}`;
    this.actionToDispatch = 'workflow/reAquireWorkflowReportCommentaryAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportRerenderClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Rerender report #${item.id}`;
    this.actionToDispatch = 'workflow/reRenderWorkflowReportAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportRecheckClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Recheck report data for #${item.id}`;
    this.actionToDispatch = 'workflow/recheckWorkflowReportDataAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
    this.isActionRecheckByReset = !(this.currentOption?.name.toLowerCase() === 'waiting');
  }

  private async onWorkflowReportReleaseDraftClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Release draft data for #${item.id}`;
    this.actionToDispatch = 'workflow/releaseWorkflowReportDraftDataAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportResendCommentaryClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Resend commentary email for #${item.id}`;
    this.actionToDispatch = 'workflow/resendWorkflowReportCommentaryEmailAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportResendApprovalForDeadlineClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Resend approval deadline email for #${item.id}`;
    this.actionToDispatch = 'workflow/resendWorkflowReportApprovalEmailAsync';
    this.additionActionDispatchOptions = { type: 'EmailForDeadline' };
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportResendApprovalClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Resend approval email for #${item.id}`;
    this.actionToDispatch = 'workflow/resendWorkflowReportApprovalEmailAsync';
    this.additionActionDispatchOptions = { type: 'Email' };
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportReturnForApprovalOneClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Return #${item.id} for approval`;
    this.actionToDispatch = 'workflow/returnWorkflowReportForApprovalOneAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportReturnForApprovalTwoClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Return #${item.id} for approval`;
    this.actionToDispatch = 'workflow/returnWorkflowReportForApprovalTwoAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportReturnForApprovalThreeClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Return #${item.id} for approval`;
    this.actionToDispatch = 'workflow/returnWorkflowReportForApprovalThreeAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportReturnForApprovalFourClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Return #${item.id} for approval`;
    this.actionToDispatch = 'workflow/returnWorkflowReportForApprovalFourAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  private async onWorkflowReportIgnoreNewDataIssuesClicked(item: WorkflowReport): Promise<void> {
    this.actionsDialogTitle = `Ignore new data issues for #${item.id}`;
    this.actionToDispatch = 'workflow/ignoreCompletedWorkflowReportNewDataIssuesAsync';
    this.additionActionDispatchOptions = {};
    this.actionItem = item;
    this.showActionsDialog = true;
  }

  async onActionOk(value: { comments: string | null, reset: boolean, flagColour: number }): Promise<void> {
    try {
      const action = this.actionToDispatch!;
      await this.$store.dispatch(action, { workflowReportId: this.actionItem!.id, reportGroupId: this.selectedReportGroup!.id, comments: value.comments, reset: value.reset, flagColour: value.flagColour, ...this.additionActionDispatchOptions });
    } catch (err) {
      this.showErrorToast('Could not perform action. Please try again.');
    }

    this.actionsDialogTitle = null;
    this.actionToDispatch = null;
    this.additionActionDispatchOptions = {};
    this.actionItem = null;
    this.showActionsDialog = false;
    this.isActionRecheckByReset = false;
    this.isActionFlag = false;
    this.reportFlagComments = null;
    this.reportFlagColour = 0;
  }

  async onActionCancel(): Promise<void> {
    this.actionsDialogTitle = null;
    this.actionToDispatch = null;
    this.additionActionDispatchOptions = {};
    this.actionItem = null;
    this.showActionsDialog = false;
    this.isActionRecheckByReset = false;
    this.isActionFlag = false;
    this.reportFlagComments = null;
    this.reportFlagColour = 0;
  }

  async onActionClose(): Promise<void> {
    this.actionsDialogTitle = null;
    this.actionToDispatch = null;
    this.showActionsDialog = false;
    this.isActionRecheckByReset = false;
    this.isActionFlag = false;
    this.reportFlagComments = null;
    this.reportFlagColour = 0;
  }

  private async onWorkflowReportBulkApproveClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Approve ${items.length} reports` : `Approve report #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkApproveWorkflowReportAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkRejectClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Reject ${items.length} reports` : `Reject report #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkRejectWorkflowReportAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkDownloadClicked(items: Array<WorkflowReport>, type: 'PDF' | 'PPTX' | 'XLSX'): Promise<void> {
    try {
      this.$store.commit('workflow/setDownloading', true);
      const zipFileName = `${this.selectedReportGroup!.name} ${date(this.selectedValuationDate!.valuationDate)} reports`;
      const data = await this.$store.dispatch('workflow/bulkDownloadWorkflowReportsAsync', {
        reportGroupId: this.selectedReportGroup!.id,
        files: items.map(item => {
          const baseName = item.fileName?.replace(/\.[^/.]+$/, '');
          const fileNameWithCorrectExtension = `${baseName}.${type.toLowerCase()}`;

          return {
            fileName: fileNameWithCorrectExtension,
            filePath: type === 'PDF' ? item.filenamePreview : item.filenameDownload,
          };
        }),
      }) as Blob;

      FileUtils.downloadFile(data, zipFileName);
    } catch (e) {
      this.showErrorToast('Could not download files. Please try again.');
    } finally {
      this.$store.commit('workflow/setDownloading', false);
    }
  }

  private async onWorkflowReportBulkFlagClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Flag ${items.length} reports` : `Flag report #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkFlagWorkflowReportAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.isActionFlag = true;
    this.reportFlagComments = null;
    this.reportFlagColour = 1;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkUnflagClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Unflag ${items.length} reports` : `Unflag report #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkUnflagWorkflowReportAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkSendDelayedCommentaryClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Send delayed commentary now for ${items.length} reports` : `Send delayed commentary now for report #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkSendWorkflowReportDelayedCommentaryAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkCancelCommentaryRaquisitionClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Cancel commentary reaquisition for ${items.length} reports` : `Cancel commentary reaquisition for report #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkCancelWorkflowReportCommentaryReaquisitionAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkDeleteClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Delete ${items.length} reports` : `Delete report #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkDeleteWorkflowReportAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkReaquireCommentaryClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Reaquire commentary for ${items.length} reports` : `Reaquire commentary for #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkReAquireWorkflowReportCommentaryAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkRerenderClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Rerender ${items.length} reports` : `Rerender report #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkReRenderWorkflowReportAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkRecheckClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Recheck report data for ${items.length} reports` : `Recheck report data for #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkRecheckWorkflowReportDataAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
    this.isActionRecheckByReset = !(this.currentOption?.name.toLowerCase() === 'waiting');
  }

  private async onWorkflowReportBulkReleaseDraftClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Release draft data for ${items.length} reports` : `Release draft data for #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkReleaseWorkflowReportDraftDataAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkResendCommentaryClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Resend commentary email for ${items.length} reports` : `Resend commentary email for #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkResendWorkflowReportCommentaryEmailAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkResendApprovalForDeadlineClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Resend approval deadline email for ${items.length} reports` : `Resend approval deadline email for #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkResendWorkflowReportApprovalEmailAsync';
    this.bulkAdditionActionDispatchOptions = { type: 'EmailForDeadline' };
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkResendApprovalClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Resend approval email for ${items.length} reports` : `Resend approval email for #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkResendWorkflowReportApprovalEmailAsync';
    this.bulkAdditionActionDispatchOptions = { type: 'Email' };
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkReturnForApprovalOneClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Return ${items.length} reports to approval one` : `Return #${items[0].id} for approval`;
    this.bulkActionToDispatch = 'workflow/bulkReturnWorkflowReportForApprovalOneAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkReturnForApprovalTwoClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Return ${items.length} reports to approval two` : `Return #${items[0].id} for approval`;
    this.bulkActionToDispatch = 'workflow/bulkReturnWorkflowReportForApprovalTwoAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkReturnForApprovalThreeClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Return ${items.length} reports to approval three` : `Return #${items[0].id} for approval`;
    this.bulkActionToDispatch = 'workflow/bulkReturnWorkflowReportForApprovalThreeAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkReturnForApprovalFourClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Return ${items.length} reports to approval four` : `Return #${items[0].id} for approval`;
    this.bulkActionToDispatch = 'workflow/bulkReturnWorkflowReportForApprovalFourAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  private async onWorkflowReportBulkRunReplacementClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Run replacements for ${items.length} reports` : `Run replacement for #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/runReplacementForWorkflowReportAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showRunReplacementDialog = true;
    this.isWorkflowMultiUpdate = false;
  }

  private async onWorkflowReportBulkIgnoreNewDataWarningsClicked(items: Array<WorkflowReport>): Promise<void> {
    this.bulkActionsDialogTitle = items.length > 1 ? `Ignore new data warnings for ${items.length} reports` : `Ignore new data warnings for #${items[0].id}`;
    this.bulkActionToDispatch = 'workflow/bulkIgnoreCompletedWorkflowReportNewDataIssuesAsync';
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = items;
    this.showBulkActionsDialog = true;
  }

  async onBulkActionOk(value: { comments: string | null, reset: boolean, useApprovalWorkflow: number, useStandardDistribution: boolean | null, includeCommentary: boolean | null, flagColour: number }): Promise<void> {
    const reports = this.bulkActionItems!;
    try {
      const action = this.bulkActionToDispatch!;
      if (reports.length > 1) {
        this.$store.commit('workflow/setLoading', true);
      }

      if (this.isWorkflowMultiUpdate) {
        const reportRequestIDs = reports.map(r => r.id).join(';');
        await this.$store.dispatch(action, { workflowReportIds: reportRequestIDs, reportGroupId: this.selectedReportGroup!.id, comments: value.comments, reset: value.reset, useApprovalWorkflow: value.useApprovalWorkflow, useStandardDistribution: value.useStandardDistribution, includeCommentary: value.includeCommentary, flagColour: value.flagColour, ...this.bulkAdditionActionDispatchOptions });
      } else {
        for (let i = 0; i < reports.length; i++) {
          const report = reports[i];
          await this.$store.dispatch(action, { workflowReportId: report.id, reportGroupId: this.selectedReportGroup!.id, comments: value.comments, reset: value.reset, useApprovalWorkflow: value.useApprovalWorkflow, useStandardDistribution: value.useStandardDistribution, includeCommentary: value.includeCommentary, flagColour: value.flagColour, ...this.bulkAdditionActionDispatchOptions });
        }
      }
    } catch (e) {
      this.showErrorToast('Could not perform actions. Please try again.');
      Sentry.captureException(e);
    }
    this.$root.$emit('bulk-workflow-action-completed', [...reports!]);

    this.onBulkActionCancel();
    this.$store.commit('workflow/setLoading', false);
  }

  onBulkActionCancel(): void {
    this.bulkActionsDialogTitle = null;
    this.bulkActionToDispatch = null;
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = [];
    this.showBulkActionsDialog = false;
    this.showRunReplacementDialog = false;
    this.isActionRecheckByReset = false;
    this.isActionFlag = false;
    this.reportFlagComments = null;
    this.reportFlagColour = 0;
    this.isWorkflowMultiUpdate = true;
  }

  onBulkActionClose(): void {
    this.bulkActionsDialogTitle = null;
    this.bulkActionToDispatch = null;
    this.bulkAdditionActionDispatchOptions = {};
    this.bulkActionItems = [];
    this.showBulkActionsDialog = false;
    this.showRunReplacementDialog = false;
    this.isActionRecheckByReset = false;
    this.isActionFlag = false;
    this.reportFlagComments = null;
    this.reportFlagColour = 0;
    this.isWorkflowMultiUpdate = true;
  }

  private onWorkflowReportReleasePendingClicked(items: Array<WorkflowReport>): void {
    this.releasePendingDialogTitle = items.length > 1 ? `Release ${items.length} reports for render` : 'Release 1 report for render';
    this.releasePendingItems = items;
    this.showReleasePendingDialog = true;
  }

  onReleasePendingCancel(): void {
    this.releasePendingDialogTitle = null;
    this.releasePendingItems = null;
    this.showReleasePendingDialog = false;
  }

  async onReleasePendingOk(): Promise<void> {
    try {
      this.$store.commit('workflow/setLoading', true);

      await this.$store.dispatch('workflow/bulkReRenderWorkflowReportAsync', { workflowReportIds: this.releasePendingItems?.map(r => r.id).join(';'), reportGroupId: this.selectedReportGroup!.id });
    } catch (err) {
      this.showErrorToast('Could not release one or more pending reports. Please try again.');
      Sentry.captureException(err);
    }

    this.onReleasePendingCancel();
    this.$store.commit('workflow/setLoading', false);
  }
}
</script>
